import React, { useState } from "react";
import "./Levis.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import bull from "../../res/images/bullseye-arrow.png";
import { Modal, Button } from "react-bootstrap";
import bulb from "../../res/images/lightbulb.png";
import fullscreen from "../../res/images/fullscreen.png";
import bolt from "../../res/images/bolt-lightning.png";

const FT = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <MegaHeader />
      <div className="strategy-container-levis">
        <div className="strategy-text-levis">
          <h1 className="levis-heading">
            Defect Detection at
            <br />
            Warp Speed: The Future
            <br /> of Textile Manufacturing
          </h1>

          <div className="levis-container" style={{ marginTop: "3vw" }}>
            <p className="levis-p">
              In a seismic shift for global textile manufacturing, Data Pilot
              has partnered with an industry titan to redefine garment quality
              control. Our bespoke AI object detection model doesn't just spot
              defects—it hunts them down with near-perfect precision. 
            </p>
          </div>
        </div>
      </div>

      {/* 1st section  */}
      <div className="row mt-7">
        <div className="col-12 d-flex align-items-center">
          <div className="d-flex">
            <img src={bull} height={100} width={80} alt="bull"></img>
            <div className="h-p">
              <h1 className="bullet">Unprecedented</h1>
              <p className="b-sub">Accuracy</p>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <p
            className="b-b-sub"
            style={{ paddingLeft: "7vw", paddingRight: "7vw" }}
          >
            Having an astounding 97% accuracy rate, our AI solution leaves
            traditional methods in the dust. It's not just an improvement; it's
            a quantum leap in quality assurance. 
          </p>
        </div>
      </div>

      {/* 2nd section  */}

      <div className="row mt-3">
        <div className="col-6 d-block align-items-center">
          <div className="d-flex">
            <img src={bolt} height={100} width={60} alt="bull"></img>
            <div className="h-p">
              <h1 className="bullet">Lightning-Fast</h1>
              <p className="b-sub">Processing</p>
            </div>
          </div>
          
          <div className="mt-3">
            <p className="b-b-sub" style={{ paddingLeft: "5.5vw" }}>
              Having an astounding 97% accuracy rate, our AI solution leaves
              traditional methods in the dust. It's not just an improvement;
              it's a quantum leap in quality assurance.  
            </p>
          </div>
          <div className="d-flex">
            <img src={bulb} height={120} width={70} alt="bull"></img>
            <div className="h-p">
              <h1 className="bullet">Collaborative</h1>
              <p className="b-sub">Innovation</p>
            </div>
          </div>
        </div>

        <div className="col-6 align-items-center px-5 ">
          <div className="d-flex">
            <img
              src={fullscreen}
              className="mb-2 fullscreen"
              alt="full-screen"
              height={20}
              width={20}
              onClick={handleShow}
            ></img>
          </div>
          <div className="w-100">
            <iframe
              // onClick={handleShow}
              // style={{ width: "70vw" }}
              src="https://datapilotcom-my.sharepoint.com/personal/hayyan_data-pilot_com/_layouts/15/embed.aspx?UniqueId=f9d3fd03-83f0-40b7-8222-9e2b3012ee7c&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
              width="100%"
              height="320"
              frameborder="0"
              scrolling="no"
              allowfullscreen
              title="Garment Defect Detection AI - model vs actual.mp4"
            ></iframe>
          </div>
        </div>
      </div>

      {/* 3rd section  */}

      <div className="row mt-1 mb-4">
        <div className="col-12 d-flex align-items-center">
          {/* <div className="d-flex">
            <img src={bulb} height={120} width={70} alt="bull"></img>
            <div className="h-p">
              <h1 className="bullet">Collaborative</h1>
              <p className="b-sub">Innovation</p>
            </div>
          </div> */}
        </div>
        <div className="col-12 mt-3">
          <p
            className="b-b-sub"
            style={{ paddingLeft: "6vw", paddingRight: "7vw" }}
          >
            The future of textile manufacturing isn't coming—it is here. Data
            Pilot's AI isn't just changing the game; it's rewriting the rules of
            what's possible in garment production.  
          </p>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Computer Vision - Garment Defect Detection</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100vh" }}>
          <iframe
            // style={{ width: "70vw" }}
            src="
https://datapilotcom-my.sharepoint.com/personal/hayyan_data-pilot_com/_layouts/15/embed.aspx?UniqueId=f9d3fd03-83f0-40b7-8222-9e2b3012ee7c&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            allowfullscreen
            title="Garment Defect Detection AI - model vs actual.mp4"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FT;
