
import dplogo from '../res/images/Group 31.png'
import circularuser from '../res/images/circle-user.png' 
import { useHistory } from "react-router";
import config from "../config";
import './navbar.css'
const MegaHeader = () =>{
    const history = useHistory();

    return(
        <>
         <header style={{position:"fixed" , zIndex:"1000", width:"100%" ,top:0}}>
        <nav className="navbar navbar-expand-lg sticky-navbar">
            <div className="container">
             <img src={dplogo} alt="logo" height={40} width={45}
              onClick={(event) =>{
                event.stopPropagation();
                history.push(config.routes.maindashboard)
              }}
             ></img>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbar-content">
                    <i className="fas fa-stream text-white"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbar-content">
                    <ul className="navbar-nav mx-5 mb-2 mb-lg-0">
                      
                        <li className="nav-item">
                            <a className="nav-link" href="#">Insight & Analytics</a>
                        </li>
                        <li className="nav-item dropdown dropdown-mega position-static">
                            <a className="nav-link " href="#" data-bs-toggle="dropdown"
                                data-bs-auto-close="outside">AI & Machine Learning</a>
                            <div className="dropdown-menu shadow">
                                <div className="mega-content px-md-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4">
                                                <h5>Top Projects</h5>
                                                <div className="list-group">
                                                    <a className="list-group-item" href="#">Accomodations</a>
                                                    <a className="list-group-item" href="#">Terms & Conditions</a>
                                                    <a className="list-group-item" href="#">Privacy</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4">
                                                <h5>Customer Analytics</h5>
                                                <div className="list-group">
                                                    <a className="list-group-item" href="#">Accomodations</a>
                                                    <a className="list-group-item" href="#">Terms & Conditions</a>
                                                    <a className="list-group-item" href="#">Privacy</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4">
                                                <h5>Sales, Product and Supply</h5>
                                                <div className="list-group">
                                                    <a className="list-group-item" href="#">Accomodations</a>
                                                    <a className="list-group-item" href="#">Terms & Conditions</a>
                                                    <a className="list-group-item" href="#">Privacy</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4">
                                                <h5>Marketing Analysis</h5>
                                                <div className="list-group">
                                                    <a className="list-group-item" href="#">Accomodations</a>
                                                    <a className="list-group-item" href="#">Terms & Conditions</a>
                                                    <a className="list-group-item" href="#">Privacy</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                    <a className="list-group-item" href="#">Lorum</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Robotic Process Automation</a>
                        </li>
                        
                    </ul>
                
                </div>
                <img src={circularuser} alt="circular-user" className="d-none d-lg-block"></img>
            </div>
        </nav>
    </header>

        </>
    )
}


export default MegaHeader;