import React, { useState } from "react";
import "./MarketingAnalyticsCs.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import arrowh from "../../res/images/arrowh.png";
import handh from "../../res/images/handh.png";
import funnelh from "../../res/images/funnelh.png";
import webh from "../../res/images/webh.png";
import doth from "../../res/images/doth.png";
import numberingh from "../../res/images/numberingh.png";
import { Modal, Button } from "react-bootstrap";
import worriedman from "../../res/images/worriedman.png";
import frame2 from "../../res/images/dpxGA.png";
import s1 from "../../res/images/s1.png";
import s2 from "../../res/images/s2.png";
import s3 from "../../res/images/s3.png";
import s4 from "../../res/images/s4.png";
import s5 from "../../res/images/s5.png";
import s6 from "../../res/images/s6.png";
import s7 from "../../res/images/s7.png";
import s8 from "../../res/images/s8.png";
import s9 from "../../res/images/s9.png";
import s10 from "../../res/images/s10.png";
import mac from "../../res/images/Mac Studio.png";

const MarketingAnalyticsCs = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <MegaHeader />
      <div className="strategy-container-mktg">
        <div className="strategy-text-mktg">
          <h1 className="mktg-main-heading">
            From Data Overload to Digital Mastery: Our Analytics Success Story
          </h1>
          <p className="mktg-subheading mt-5">
            Witnessing an advanced analytics approach boost website performance,
            increasing user engagement, and ignite conversions across the globe
          </p>
        </div>
      </div>

      <div className="row" style={{ marginTop: "10vw" }}>
        <div className="col-4 d-flex align-items-center">
          <img
            onClick={handleShow}
            src={frame2}
            // height={350}
            // width={350}
            alt="lp"
            style={{ cursor: "pointer" }}
          ></img>
        </div>
        <div className="col-lg-8 col-md-8 px-5">
          <div>
            <p className="problem">Problem</p>
            <p className="pb-subheading">
              Data Pilot was drowning in unused data, missing out on prime
              opportunities to dominate search results, engage visitors, and
              turn clicks into customers. Our digital presence needed awakening.
            </p>
          </div>

          <div>
            <p className="problem">Solution</p>
            <p className="pb-subheading">
              We built a game-changing analytics dashboard, fusing the might of
              Google Analytics and Search Console featuring:
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="d-flex">
                <img src={s1} alt="80"></img>

                <p style={{ marginLeft: "1vw" }}>
                  Laser-focused performance metrics 
                </p>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex">
                <img src={s2} alt="80"></img>

                <p style={{ marginLeft: "1vw" }}>Hidden keyword gems </p>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex">
                <img src={s3} alt="80"></img>

                <p style={{ marginLeft: "1vw" }}>Landing page superstars </p>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex">
                <img src={s4} alt="80"></img>

                <p style={{ marginLeft: "1vw" }}>
                  The secret sauce of user engagement 
                </p>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex">
                <img src={s5} alt="80"></img>

                <p style={{ marginLeft: "1vw" }}>
                  A world map of untapped markets 
                </p>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex">
                <img src={s6} alt="80"></img>

                <p style={{ marginLeft: "1vw" }}>
                  The alchemy of turning campaigns into conversions 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "10vw" }}>
        <div className="col-lg-6 col-md-6 px-5">
          <p className="problem">Impact</p>
          <div className="d-flex">
            <img src={s7} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              CTR skyrocketed to <strong>1.57%</strong>, with a jaw-dropping{" "}
              <strong>45,242</strong> impressions and <strong>8,471</strong> active users
              swarming the site 
            </p>
          </div>

          <div className="d-flex">
            <img src={s8} alt="81"></img>

            <p style={{ marginLeft: "1vw" }}>
              Captivated audiences like never before, with <strong>43%</strong> of visitors
              devouring <strong>75%</strong> of content 
            </p>
          </div>

          <div className="d-flex">
            <img src={s9} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Planted digital flags across the globe, with the US leading the
              charge and new markets emerging at lightning speed 
            </p>
          </div>

          <div className="d-flex mt-2">
            <img src={s10} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Turbo-charged conversions: <strong>48</strong> potential clients reached out, with
              <strong>6</strong>ready to take the plunge 
            </p>
          </div>

          <div className="d-flex mt-2">
            <img src={s3} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Unmasked the superstar campaigns, paving the way for laser-focused
              marketing domination 
            </p>
          </div>
        </div>

        <div className="col-6 ">
          <img
            className="w-100"
            onClick={handleShow}
            src={mac}
            height={700}
            // width={350}
            alt="lp"
            style={{ cursor: "pointer" }}
          ></img>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>GA 4</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="d"
            style={{ width: "100%", height: "700px" }}
            frameBorder="0"
            allowFullScreen="true"
            src="https://lookerstudio.google.com/embed/reporting/7ff6140c-23ef-4463-a1a1-dafcf3e818ec/page/p_pjz486avjd"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MarketingAnalyticsCs;
